import React from 'react'
import './Home.css'
import gif from '../../assets/img/Hero.gif'

function Home() {
  return (
    <section className='Home'>

        <div className="Home-container">

        <div className="Hero-img-container">
            <img src={gif} alt="" />
        </div>

        <div className="Hero-text-container">
        <p>Sorry, We were too lazy to finish the website....</p>
        <p>Here is our links!</p>
        </div>
        </div>
        <div className="Hero-mid-container">
            <p>Community Discord | GitHub | Email</p>
        </div>
        <div className="Hero-bottom-container">
            <p>Scroll down to find out more...</p>
        </div>
    </section>
  )
}

export default Home